import Head from 'next/head';
import { FC } from 'react';

interface MetaBlockProps {
  title: string;
  description?: string;
  keywords?: string;
  leadImage?: string;
  children?: React.ReactNode;
}

const MetaBlock: FC<MetaBlockProps> = (props) => {
  const { title, description, leadImage, keywords, children } = props;

  return (
    <Head>
      <meta charSet="utf-8" />

      <title>{title}</title>
      <meta name="title" content={title} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="twitter:description" content={description} />
        </>
      )}

      {keywords && (
        <>
          <meta name="keywords" content={keywords} />
        </>
      )}
      {leadImage && (
        <>
          <meta property="og:image" content={leadImage} />
          <meta property="twitter:image" content={leadImage} />
        </>
      )}
      {children}
    </Head>
  );
};

export default MetaBlock;
