import { useEffect } from 'react';

import Button from '@/components/generics/button';
import MetaBlock from '@/components/meta-block/MetaBlock';
import { useLayoutContext } from '@/context/LayoutContext';
import Link from 'next/link';

const NotFound = () => {
  const { setCenter } = useLayoutContext();

  useEffect(() => {
    setCenter?.(true);

    return () => {
      setCenter?.(false);
    };
  }, []);

  return (
    <>
      <MetaBlock title="Not Found | Eternal Engine Corp" />
      <div className="text-center mt-5 mb-5">
        <div className="fs-1 fw-bold">404</div>
        <p className="fs-3">
          Oops! The page you are looking for doesn’t exist. Try the button
          below...
        </p>
        <Link href={'/'}>
          <Button>Back to home</Button>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
